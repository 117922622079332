// Third-Party
// N/A
// Custom
import { FastbootConnectMenuView } from "./view";
// Resources
// N/A

const FastbootConnectMenuContainer = () => {
    // -------------------------------------------------------------------------
    // State
    // -------------------------------------------------------------------------
    // N/A

    // -------------------------------------------------------------------------
    // Life Cycle
    // -------------------------------------------------------------------------
    // N/A

    // -------------------------------------------------------------------------
    // Rendering
    // -------------------------------------------------------------------------
    const final_render_element = (
        <div>
            <FastbootConnectMenuView />
        </div>
    );

    return final_render_element;
};

export { FastbootConnectMenuContainer };
