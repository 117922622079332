// -----------------------------------------------------------------------------
// Third-Party Libraries
// -----------------------------------------------------------------------------
import DeepCopy from "deepcopy";
import * as React from "react";
// -----------------------------------------------------------------------------
// Custom Components
// -----------------------------------------------------------------------------
import { BlobStore } from "./fastbootjs_source_code/download";
import { FastbootDevice } from "./fastbootjs_source_code/fastboot";
// -----------------------------------------------------------------------------
// Resources
// -----------------------------------------------------------------------------
// N/A

// -----------------------------------------------------------------------------
// State
// -----------------------------------------------------------------------------
interface IDeviceCache {
    product_name: null | string;
    serial_number: null | string;
}
const default_fastbootjs_device_cache: IDeviceCache = {
    product_name: null,
    serial_number: null,
};

interface IFastbootJSState {
    blob_store: null | BlobStore;
    device: null | FastbootDevice;
    device_cache: IDeviceCache;
}
const default_fastbootjs_state: IFastbootJSState = {
    blob_store: null,
    device: null,
    device_cache: default_fastbootjs_device_cache,
};

// -----------------------------------------------------------------------------
// Context
// -----------------------------------------------------------------------------
const FastbootJSDispatchContext = React.createContext({});
const FastbootJSStateContext = React.createContext(default_fastbootjs_state);

// -----------------------------------------------------------------------------
// Reducer
// -----------------------------------------------------------------------------
enum FastbootJSActionType {
    SET_BLOB_STORE = "SET_BLOB_STORE",
    SET_DEVICE = "SET_DEVICE",
    SET_DEVICE_CACHE = "SET_DEVICE_CACHE",
    RESET = "RESET",
}

interface IFastbootJSAction {
    payload: any;
    type: FastbootJSActionType;
}

function FastbootJSReducer(current_state: IFastbootJSState, action: IFastbootJSAction): IFastbootJSState {
    // console.log("-------------------");
    // console.log("FastbootJS ACTION UPDATE");
    // console.log(current_state);
    // console.log(action);

    let new_state = DeepCopy(current_state);

    // For some reason if we don't do this it breack the device's information,
    // ALWAYS reset the device because `DeepCopy` messes it up otherwise
    new_state.device = current_state.device;

    switch (action.type) {
        case FastbootJSActionType.SET_BLOB_STORE:
            new_state.blob_store = action.payload.blob_store;
            break;
        case FastbootJSActionType.SET_DEVICE:
            new_state.device = action.payload.device;
            break;
        case FastbootJSActionType.SET_DEVICE_CACHE:
            new_state.device_cache = action.payload.device_cache;
            break;
        case FastbootJSActionType.RESET:
            new_state = {
                ...default_fastbootjs_state,
            };
            break;
        default:
            break;
    }

    return new_state;
}

// -----------------------------------------------------------------------------
// Provider
// -----------------------------------------------------------------------------
function FastbootJSProvider({ children }: any) {
    const [fastbootjs_state, fastbootJSDispatch] = React.useReducer(FastbootJSReducer, default_fastbootjs_state);

    const final_render_element = (
        <FastbootJSStateContext.Provider value={fastbootjs_state}>
            <FastbootJSDispatchContext.Provider value={fastbootJSDispatch}>
                {children}
            </FastbootJSDispatchContext.Provider>
        </FastbootJSStateContext.Provider>
    );

    return final_render_element;
}

export {
    default_fastbootjs_state,
    FastbootJSActionType,
    FastbootJSDispatchContext,
    FastbootJSStateContext,
    FastbootJSProvider,
};
