import * as Sparse from "./sparse";
import * as common from "./common";
import { FactoryProgressCallback } from "./factory";
import {
    // Constants
    SPARSE_FILE_HEADER_SIZE_IN_BYTES,
    // Functions
    extractSignatureArrayBuffer,
    parseFileHeader,
    splitSparseFileBlob,
} from "./neo_sparse";

const FASTBOOT_USB_CLASS = 0xff;
const FASTBOOT_USB_SUBCLASS = 0x42;
const FASTBOOT_USB_PROTOCOL = 0x03;

const BULK_TRANSFER_SIZE = 16384;

const DEFAULT_DOWNLOAD_SIZE = 512 * 1024 * 1024; // 512 MiB
// To conserve RAM and work around Chromium's ~2 GiB size limit, we limit the
// max download size even if the bootloader can accept more data.
const MAX_DOWNLOAD_SIZE = 1024 * 1024 * 1024; // 1 GiB

const GETVAR_TIMEOUT = 10000; // ms

/**
 * Exception class for USB errors not directly thrown by WebUSB.
 */
export class UsbError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "UsbError";
    }
}

/**
 * Exception class for errors returned by the bootloader, as well as high-level
 * fastboot errors resulting from bootloader responses.
 */
export class FastbootError extends Error {
    status: string;
    bootloaderMessage: string;

    constructor(status: string, message: string) {
        super(`Bootloader replied with ${status}: ${message}`);
        this.status = status;
        this.bootloaderMessage = message;
        this.name = "FastbootError";
    }
}

interface CommandResponse {
    text: string;
    // hex string from DATA
    dataSize?: string;
}

/**
 * Callback for progress updates while flashing or uploading an image.
 *
 * @callback FlashProgressCallback
 * @param {number} progress - Progress for the current action, between 0 and 1.
 */
export type FlashProgressCallback = (progress: number) => void;

/**
 * Callback for reconnecting to the USB device.
 * This is necessary because some platforms do not support automatic reconnection,
 * and USB connection requests can only be triggered as the result of explicit
 * user action.
 *
 * @callback ReconnectCallback
 */
export type ReconnectCallback = () => void;

/**
 * This class is a client for executing fastboot commands and operations on a
 * device connected over USB.
 */
export class FastbootDevice {
    device: USBDevice | null;
    endpointIn: number | null;
    endpointOut: number | null;

    private _registeredUsbListeners: boolean;
    private _connectResolve: ((value: any) => void) | null;
    private _connectReject: ((err: Error) => void) | null;
    private _disconnectResolve: ((value: any) => void) | null;

    /**
     * Create a new fastboot device instance. This doesn't actually connect to
     * any USB devices; call {@link connect} to do so.
     */
    constructor() {
        this.device = null;
        this.endpointIn = null;
        this.endpointOut = null;

        this._registeredUsbListeners = false;
        this._connectResolve = null;
        this._connectReject = null;
        this._disconnectResolve = null;
    }

    /**
     * Returns whether a USB device is connected and ready for use.
     */
    public isConnected(): boolean {
        return this.device !== null && this.device.opened && this.device.configurations[0].interfaces[0].claimed;
    }

    /**
     * Validate the current USB device's details and connect to it.
     *
     * @private
     */
    private async _validateAndConnectDevice() {
        if (this.device === null) {
            throw new UsbError("Attempted to connect to null device");
        }

        // Validate device
        let ife = this.device!.configurations[0].interfaces[0].alternates[0];
        if (ife.endpoints.length !== 2) {
            throw new UsbError("Interface has wrong number of endpoints");
        }

        this.endpointIn = null;
        this.endpointOut = null;
        for (let endpoint of ife.endpoints) {
            common.logVerbose("Checking endpoint:", endpoint);
            if (endpoint.type !== "bulk") {
                throw new UsbError("Interface endpoint is not bulk");
            }

            if (endpoint.direction === "in") {
                if (this.endpointIn === null) {
                    this.endpointIn = endpoint.endpointNumber;
                } else {
                    throw new UsbError("Interface has multiple IN endpoints");
                }
            } else if (endpoint.direction === "out") {
                if (this.endpointOut === null) {
                    this.endpointOut = endpoint.endpointNumber;
                } else {
                    throw new UsbError("Interface has multiple OUT endpoints");
                }
            }
        }
        common.logVerbose("Endpoints: in =", this.endpointIn, ", out =", this.endpointOut);

        try {
            await this.device!.open();
            // Opportunistically reset to fix issues on some platforms
            try {
                await this.device!.reset();
            } catch (error) {
                /* Failed = doesn't support reset */
            }

            await this.device!.selectConfiguration(1);
            await this.device!.claimInterface(0); // fastboot
        } catch (error) {
            // Propagate exception from waitForConnect()
            if (this._connectReject !== null) {
                this._connectReject(error as Error);
                this._connectResolve = null;
                this._connectReject = null;
            }

            throw error;
        }

        // Return from waitForConnect()
        if (this._connectResolve !== null) {
            this._connectResolve(undefined);
            this._connectResolve = null;
            this._connectReject = null;
        }
    }

    /**
     * Wait for the current USB device to disconnect, if it's still connected.
     * Returns immediately if no device is connected.
     */
    async waitForDisconnect() {
        if (this.device === null) {
            return;
        }

        return await new Promise((resolve, _reject) => {
            this._disconnectResolve = resolve;
        });
    }

    /**
     * Wait for the USB device to connect. Returns at the next connection,
     * regardless of whether the connected USB device matches the previous one.
     *
     * @param {ReconnectCallback} onReconnect - Callback to request device reconnection on Android.
     */
    async waitForConnect(onReconnect: ReconnectCallback = () => {}) {
        // On Android, we need to request the user to reconnect the device manually
        // because there is no support for automatic reconnection.
        if (navigator.userAgent.includes("Android")) {
            await this.waitForDisconnect();
            onReconnect();
        }

        return await new Promise((resolve, reject) => {
            this._connectResolve = resolve;
            this._connectReject = reject;
        });
    }

    /**
     * Request the user to select a USB device and connect to it using the
     * fastboot protocol.
     *
     * @throws {UsbError}
     */
    async connect() {
        let devices = await navigator.usb.getDevices();
        console.log("Found paired USB devices:", devices);
        if (devices.length === 1) {
            this.device = devices[0];
        } else {
            // If multiple paired devices are connected, request the user to
            // select a specific one to reduce ambiguity. This is also necessary
            // if no devices are already paired, i.e. first use.
            console.log("No or multiple paired devices are connected, requesting one");
            this.device = await navigator.usb.requestDevice({
                filters: [
                    // {
                    //     classCode: FASTBOOT_USB_CLASS,
                    //     subclassCode: FASTBOOT_USB_SUBCLASS,
                    //     protocolCode: FASTBOOT_USB_PROTOCOL,
                    // },
                ],
            });
        }
        console.log("Using USB device:", this.device);

        if (!this._registeredUsbListeners) {
            console.log("No registered USB listeners");
            navigator.usb.addEventListener("disconnect", (event) => {
                if (event.device === this.device) {
                    console.log("USB device disconnected");
                    if (this._disconnectResolve !== null) {
                        this._disconnectResolve(undefined);
                        this._disconnectResolve = null;
                    }
                }
            });

            navigator.usb.addEventListener("connect", async (event) => {
                console.log("USB device connected");
                this.device = event.device;

                // Check whether waitForConnect() is pending and save it for later
                let hasPromiseReject = this._connectReject !== null;
                try {
                    await this._validateAndConnectDevice();
                } catch (error) {
                    // Only rethrow errors from the event handler if waitForConnect()
                    // didn't already handle them
                    if (!hasPromiseReject) {
                        throw error;
                    }
                }
            });

            this._registeredUsbListeners = true;
        }

        console.log("Default _validateAndConnectDevice");
        await this._validateAndConnectDevice();
    }

    async disconnect() {
        try {
            if (this.device !== null && this.device !== undefined) {
                await this.device.close();
                this.device = null;
            }
        } catch (e: any) {
            console.error(e);
        }
    }

    /**
     * Read a raw command response from the bootloader.
     *
     * @private
     * @returns {Promise<CommandResponse>} Object containing response text and data size, if any.
     * @throws {FastbootError}
     */
    private async _readResponse(): Promise<CommandResponse> {
        let respData = {
            text: "",
        } as CommandResponse;
        let respStatus;

        do {
            let respPacket = await this.device!.transferIn(this.endpointIn!, 64);
            let response = new TextDecoder().decode(respPacket.data);

            respStatus = response.substring(0, 4);
            let respMessage = response.substring(4);
            console.log(`Response: ${respStatus} ${respMessage}`);

            if (respStatus === "OKAY") {
                // OKAY = end of response for this command
                respData.text += respMessage;
            } else if (respStatus === "INFO") {
                // INFO = additional info line
                respData.text += respMessage + "\n";
            } else if (respStatus === "DATA") {
                // DATA = hex string, but it's returned separately for safety
                respData.dataSize = respMessage;
            } else {
                // Assume FAIL or garbage data
                throw new FastbootError(respStatus, respMessage);
            }
            // INFO = more packets are coming
        } while (respStatus === "INFO");

        return respData;
    }

    /**
     * Send a textual command to the bootloader and read the response.
     * This is in raw fastboot format, not AOSP fastboot syntax.
     *
     * @param {string} command - The command to send.
     * @returns {Promise<CommandResponse>} Object containing response text and data size, if any.
     * @throws {FastbootError}
     */
    async runCommand(command: string): Promise<CommandResponse> {
        // Command and response length is always 64 bytes regardless of protocol
        if (command.length > 64) {
            throw new RangeError();
        }

        // Send raw UTF-8 command
        let cmdPacket = new TextEncoder().encode(command);
        await this.device!.transferOut(this.endpointOut!, cmdPacket);
        console.log("Command:", command);

        return this._readResponse();
    }

    /**
     * Read the value of a bootloader variable. Returns undefined if the variable
     * does not exist.
     *
     * @param {string} varName - The name of the variable to get.
     * @returns {Promise<string>} Textual content of the variable.
     * @throws {FastbootError}
     */
    async getVariable(varName: string): Promise<string | null> {
        let resp;
        try {
            resp = (await common.runWithTimeout(this.runCommand(`getvar:${varName}`), GETVAR_TIMEOUT)).text;
        } catch (error) {
            // Some bootloaders return FAIL instead of empty responses, despite
            // what the spec says. Normalize it here.
            if (error instanceof FastbootError && error.status == "FAIL") {
                resp = null;
            } else {
                throw error;
            }
        }

        // Some bootloaders send whitespace around some variables.
        // According to the spec, non-existent variables should return empty
        // responses
        return resp ? resp.trim() : null;
    }

    /**
     * Get the maximum download size for a single payload, in bytes.
     *
     * @private
     * @returns {Promise<number>}
     * @throws {FastbootError}
     */
    private async _getDownloadSize(): Promise<number> {
        try {
            let resp = (await this.getVariable("max-download-size"))!.toLowerCase();
            if (resp) {
                // AOSP fastboot requires hex
                return Math.min(parseInt(resp, 16), MAX_DOWNLOAD_SIZE);
            }
        } catch (error) {
            /* Failed = no value, fallthrough */
        }

        // FAIL or empty variable means no max, set a reasonable limit to conserve memory
        return DEFAULT_DOWNLOAD_SIZE;
    }

    /**
     * Send a raw data payload to the bootloader.
     *
     * @private
     */
    private async _sendRawPayload(buffer: ArrayBuffer, onProgress: FlashProgressCallback) {
        let i = 0;
        let remainingBytes = buffer.byteLength;
        while (remainingBytes > 0) {
            let chunk = buffer.slice(i * BULK_TRANSFER_SIZE, (i + 1) * BULK_TRANSFER_SIZE);
            if (i % 1000 === 0) {
                common.logVerbose(
                    `  Sending ${chunk.byteLength} bytes to endpoint, ${remainingBytes} remaining, i=${i}`
                );
            }
            if (i % 10 === 0) {
                onProgress((buffer.byteLength - remainingBytes) / buffer.byteLength);
            }

            await this.device!.transferOut(this.endpointOut!, chunk);

            remainingBytes -= chunk.byteLength;
            i += 1;
        }

        onProgress(1.0);
    }

    /**
     * Upload a payload to the bootloader for later use, e.g. flashing.
     * Does not handle raw images, flashing, or splitting.
     *
     * @param {string} partition - Name of the partition the payload is intended for.
     * @param {ArrayBuffer} buffer - Buffer containing the data to upload.
     * @param {FlashProgressCallback} onProgress - Callback for upload progress updates.
     * @throws {FastbootError}
     */
    async upload(partition: string, buffer: ArrayBuffer, onProgress: FlashProgressCallback = (_progress) => {}) {
        console.log(`Uploading single sparse to ${partition}: ${buffer.byteLength} bytes`);

        // Bootloader requires an 8-digit hex number
        let transferHex = buffer.byteLength.toString(16).padStart(8, "0");
        if (transferHex.length !== 8) {
            throw new FastbootError("FAIL", `Transfer size overflow: ${transferHex} is more than 8 digits`);
        }

        // Check with the device and make sure size matches
        let downloadResponse = await this.runCommand(`download:${transferHex}`);
        if (downloadResponse.dataSize === undefined) {
            throw new FastbootError("FAIL", `Unexpected response to download command: ${downloadResponse.text}`);
        }
        let downloadSize = parseInt(downloadResponse.dataSize!, 16);
        if (downloadSize !== buffer.byteLength) {
            throw new FastbootError(
                "FAIL",
                `Bootloader wants ${buffer.byteLength} bytes, requested to send ${buffer.byteLength} bytes`
            );
        }

        console.log(`Sending payload: ${buffer.byteLength} bytes`);
        await this._sendRawPayload(buffer, onProgress);

        console.log("Payload sent, waiting for response...");
        await this._readResponse();
    }

    /**
     * Reboot to the given target, and optionally wait for the device to
     * reconnect.
     *
     * @param {string} target - Where to reboot to, i.e. fastboot or bootloader.
     * @param {boolean} wait - Whether to wait for the device to reconnect.
     * @param {ReconnectCallback} onReconnect - Callback to request device reconnection, if wait is enabled.
     */
    async reboot(target: string = "", wait: boolean = false, onReconnect: ReconnectCallback = () => {}) {
        if (target.length > 0) {
            await this.runCommand(`reboot-${target}`);
        } else {
            await this.runCommand("reboot");
        }

        if (wait) {
            await this.waitForConnect(onReconnect);
        }
    }

    /**
     * Flash the given Blob to the given partition on the device. Any image
     * format supported by the bootloader is allowed, e.g. sparse or raw images.
     * Large raw images will be converted to sparse images automatically, and
     * large sparse images will be split and flashed in multiple passes
     * depending on the bootloader's payload size limit.
     *
     * @param {string} partition - The name of the partition to flash.
     * @param {Blob} blob - The Blob to retrieve data from.
     * @param {FlashProgressCallback} onProgress - Callback for flashing progress updates.
     * @throws {FastbootError}
     */
    async flashBlob(partition: string, blob: Blob, onProgress: FlashProgressCallback = (_progress) => {}) {
        // Use current slot if partition is A/B
        if ((await this.getVariable(`has-slot:${partition}`)) === "yes") {
            partition += "_" + (await this.getVariable("current-slot"));
        }

        let maxDlSize = await this._getDownloadSize();
        let fileHeader = await common.readBlobAsBuffer(blob.slice(0, Sparse.FILE_HEADER_SIZE));

        let totalBytes = blob.size;
        let isSparse = false;
        try {
            let sparseHeader = parseFileHeader(fileHeader);
            if (sparseHeader !== null) {
                totalBytes = sparseHeader.blocks * sparseHeader.blockSize;
                isSparse = true;
            }
        } catch (error) {
            // ImageError = invalid, so keep blob.size
        }

        // Logical partitions need to be resized before flashing because they're
        // sized perfectly to the payload.
        if ((await this.getVariable(`is-logical:${partition}`)) === "yes") {
            // As per AOSP fastboot, we reset the partition to 0 bytes first
            // to optimize extent allocation.
            await this.runCommand(`resize-logical-partition:${partition}:0`);
            // Set the actual size
            await this.runCommand(`resize-logical-partition:${partition}:${totalBytes}`);
        }

        // Convert image to sparse (for splitting) if it exceeds the size limit
        if (blob.size > maxDlSize && !isSparse) {
            console.log(`${partition} image is raw, converting to sparse`);

            // Assume that non-sparse images will always be small enough to convert in RAM.
            // The buffer is converted to a Blob for compatibility with the existing flashing code.
            let rawData = await common.readBlobAsBuffer(blob);
            let sparse = Sparse.fromRaw(rawData);
            blob = new Blob([sparse]);
        }

        console.log(`Flashing ${blob.size} bytes to ${partition}, ${maxDlSize} bytes per split`);
        console.log(`maxDlSize: ${maxDlSize}`);
        console.log(`totalBytes: ${totalBytes}`);
        let splits = 0;
        let sentBytes = 0;
        for await (let split of Sparse.splitBlob(blob, maxDlSize, partition === "wearable" ? true : false)) {
            console.log("====================");
            console.log(`split: ${splits}`);
            await this.upload(partition, split.data, (progress) => {
                onProgress((sentBytes + progress * split.bytes) / totalBytes);
            });

            console.log("Flashing payload...");
            await this.runCommand(`flash:${partition}`);

            splits += 1;
            sentBytes += split.bytes;
        }

        console.log(`Flashed ${partition} with ${splits} split(s)`);
    }

    // /**
    //  * Flash the given factory images zip onto the device, with automatic handling
    //  * of firmware, system, and logical partitions as AOSP fastboot and
    //  * flash-all.sh would do.
    //  * Equivalent to `fastboot update name.zip`.
    //  *
    //  * @param {Blob} blob - Blob containing the zip file to flash.
    //  * @param {boolean} wipe - Whether to wipe super and userdata. Equivalent to `fastboot -w`.
    //  * @param {ReconnectCallback} onReconnect - Callback to request device reconnection.
    //  * @param {FactoryProgressCallback} onProgress - Progress callback for image flashing.
    //  */
    // async flashFactoryZip(
    //     blob: Blob,
    //     wipe: boolean,
    //     onReconnect: ReconnectCallback,
    //     onProgress: FactoryProgressCallback = (_progress) => {}
    // ) {
    //     return await flashFactoryZip(this, blob, wipe, onReconnect, onProgress);
    // }
    // -------------------------------------------------------------------------
    // NEW
    // -------------------------------------------------------------------------
    // --------------------
    // Download
    // --------------------
    async downloadFile(partition: string, blob: Blob, onProgress: FlashProgressCallback = (_progress) => {}) {
        console.log("=======================");
        console.log("Beginning File Download");

        // --------------------
        // ?????
        // --------------------
        // Use current slot if partition is A/B
        if ((await this.getVariable(`has-slot:${partition}`)) === "yes") {
            partition += "_" + (await this.getVariable("current-slot"));
            console.log(`New Partition Value: ${partition}`);
        }

        // --------------------
        // File Validation
        // --------------------
        let maxDownloadSizeInBytes = await this._getDownloadSize();
        let fileHeader = await common.readBlobAsBuffer(blob.slice(0, SPARSE_FILE_HEADER_SIZE_IN_BYTES));

        let fileSizeInBytes = blob.size;
        let isFileASparseImage = false;
        try {
            let sparseHeader = parseFileHeader(fileHeader);
            if (sparseHeader !== null) {
                fileSizeInBytes = sparseHeader.blocks * sparseHeader.blockSize;
                isFileASparseImage = true;
                console.log(`Sparse File Header Found`);
            } else {
                console.log(
                    "The file provided does not appear to be a Sparse file." +
                        "\n" +
                        "There does not appear to be a Sparse File Header at the beginning of the file."
                );
            }
        } catch (error) {
            // ImageError = invalid, so keep blob.size
            console.error(error);
        }

        // --------------------
        // Device Preparation (OLD STUFF)
        // --------------------
        // Logical partitions need to be resized before flashing because they're
        // sized perfectly to the payload.
        if ((await this.getVariable(`is-logical:${partition}`)) === "yes") {
            // As per AOSP fastboot, we reset the partition to 0 bytes first
            // to optimize extent allocation.
            await this.runCommand(`resize-logical-partition:${partition}:0`);
            // Set the actual size
            await this.runCommand(`resize-logical-partition:${partition}:${fileSizeInBytes}`);
        }

        // Logan Note: This does not seem to executed by any other code paths
        // Convert image to sparse (for splitting) if it exceeds the size limit
        if (blob.size > maxDownloadSizeInBytes && !isFileASparseImage) {
            console.log(`${partition} image is raw, converting to sparse`);

            // Assume that non-sparse images will always be small enough to convert in RAM.
            // The buffer is converted to a Blob for compatibility with the existing flashing code.
            let rawData = await common.readBlobAsBuffer(blob);
            let sparse = Sparse.fromRaw(rawData);
            blob = new Blob([sparse]);
        }

        // --------------------
        // Upload Data
        // --------------------
        console.log(`Flashing ${blob.size} bytes to ${partition}, ${maxDownloadSizeInBytes} bytes per split`);

        const canFileBeUploadedWithoutSplitting = blob.size <= maxDownloadSizeInBytes;
        if (canFileBeUploadedWithoutSplitting === true) {
            console.log("Blob fits in 1 payload, not splitting");

            const fileData = await common.readBlobAsBuffer(blob);
            await this.upload(partition, fileData, (progress) => {
                console.log(`Upload Progress: ${progress}`);
            });
        } else {
            console.log("Blob needs to be split for upload");
            let splits = 0;
            let sentBytes = 0;
            for await (let currentSparseFileBlobSplit of splitSparseFileBlob(blob, maxDownloadSizeInBytes)) {
                console.log(`split: ${splits}`);
                console.log("currentSparseFileBlobSplit");
                console.log(currentSparseFileBlobSplit);
                await this.upload(
                    partition,
                    currentSparseFileBlobSplit.data
                    // (progress) => {
                    //     onProgress((sentBytes + progress * currentSparseFileBlobSplit.bytes) / fileSizeInBytes);
                    // }
                );
                splits += 1;
                sentBytes += currentSparseFileBlobSplit.bytes;
            }

            console.log("Flashing payload...");
            await this.runCommand(`flash:${partition}`);
        }
    }

    // --------------------
    // Verify Signature
    // --------------------
    async uploadAndVerifySparseSignature(
        partition: string,
        fileBlob: Blob,
        onProgress: FlashProgressCallback = (_progress) => {}
    ) {
        console.log(`Beginning Verification`);

        // --------------------
        // ?????
        // --------------------
        // Use current slot if partition is A/B

        if ((await this.getVariable(`has-slot:${partition}`)) === "yes") {
            partition += "_" + (await this.getVariable("current-slot"));
            console.log(`New Partition Value: ${partition}`);
        }

        // --------------------
        // File Validation
        // --------------------
        let maxDownloadSizeInBytes = await this._getDownloadSize();

        const fileHeaderBlob = fileBlob.slice(0, SPARSE_FILE_HEADER_SIZE_IN_BYTES);
        const fileHeaderData = await common.readBlobAsBuffer(fileHeaderBlob);
        let fileHeaderObject: Sparse.SparseHeader | null = null;

        let fileSizeInBytes = fileBlob.size;
        let isFileASparseImage = false;
        try {
            fileHeaderObject = parseFileHeader(fileHeaderData);
            if (fileHeaderObject !== null) {
                fileSizeInBytes = fileHeaderObject.blocks * fileHeaderObject.blockSize;
                isFileASparseImage = true;
                console.log(`Sparse File Header Found`);
            } else {
                console.log(
                    "The file provided does not appear to be a Sparse file." +
                        "\n" +
                        "There does not appear to be a Sparse File Header at the beginning of the file."
                );
            }
        } catch (error) {
            // ImageError = invalid, so keep fileBlob.size
            console.error(error);
        }

        // --------------------
        // Device Preparation (OLD STUFF)
        // --------------------
        // Logical partitions need to be resized before flashing because they're
        // sized perfectly to the payload.
        if ((await this.getVariable(`is-logical:${partition}`)) === "yes") {
            // As per AOSP fastboot, we reset the partition to 0 bytes first
            // to optimize extent allocation.
            await this.runCommand(`resize-logical-partition:${partition}:0`);
            // Set the actual size
            await this.runCommand(`resize-logical-partition:${partition}:${fileSizeInBytes}`);
        }

        // Logan Note: This does not seem to executed by any other code paths
        // Convert image to sparse (for splitting) if it exceeds the size limit
        if (fileBlob.size > maxDownloadSizeInBytes && !isFileASparseImage) {
            console.log(`${partition} image is raw, converting to sparse`);

            // Assume that non-sparse images will always be small enough to convert in RAM.
            // The buffer is converted to a Blob for compatibility with the existing flashing code.
            let rawData = await common.readBlobAsBuffer(fileBlob);
            let sparse = Sparse.fromRaw(rawData);
            fileBlob = new Blob([sparse]);
        }

        // --------------------
        // Upload Data
        // --------------------
        try {
            const signatureArrayBuffer = await extractSignatureArrayBuffer(fileBlob);
            console.log(signatureArrayBuffer);

            console.log("Uploading Signature");
            await this.upload(partition, signatureArrayBuffer, (progress) => {
                // TODO
            });

            // --------------------
            // Set Signature
            // --------------------
            console.log("Verifying Signature");
            await this.runCommand("set_sparse_siginfo");
        } catch (error) {
            console.log(`Verify Signature Error: ${error}`);
        }
    }
}
