// Third-Party
import React from "react";
import { DefaultButton, Dialog, Dropdown, PrimaryButton, ProgressIndicator, Stack, StackItem } from "@fluentui/react";
// Custom
import { FastbootJSActionType, FastbootJSDispatchContext, FastbootJSStateContext } from "../FastbootJS";
import { BlobStore } from "../FastbootJS/fastbootjs_source_code/download";
import { FastbootDevice } from "../FastbootJS/fastbootjs_source_code/fastboot";
// Resources
import { Icons } from "../../utils";

const FastbootConnectMenuView = () => {
    // -------------------------------------------------------------------------
    // State
    // -------------------------------------------------------------------------
    // Local State
    const [supported, setSupported] = React.useState(true);
    // Context
    const fastbootjs_state = React.useContext(FastbootJSStateContext);
    const fastbootjsDispatch: any = React.useContext(FastbootJSDispatchContext);
    // Helpers
    const is_device_connected = fastbootjs_state.device !== null && fastbootjs_state.device.isConnected() === true;

    // -------------------------------------------------------------------------
    // Life Cycle
    // -------------------------------------------------------------------------
    //N/A

    // -------------------------------------------------------------------------
    // Logic
    // -------------------------------------------------------------------------
    const connectToDevice = React.useCallback(async () => {
        const canNewDeviceBeConnected = fastbootjs_state.blob_store === null && fastbootjs_state.device === null;
        if (canNewDeviceBeConnected === true) {
            const new_blob_store = new BlobStore();
            const new_device = new FastbootDevice();

            try {
                await new_device.connect();

                const newProductName = await new_device.getVariable("product");
                const newSerialNumber = await new_device.getVariable("serialno");

                // -----
                fastbootjsDispatch({
                    payload: {
                        blob_store: new_blob_store,
                    },
                    type: FastbootJSActionType.SET_BLOB_STORE,
                });
                fastbootjsDispatch({
                    payload: {
                        device: new_device,
                    },
                    type: FastbootJSActionType.SET_DEVICE,
                });
                fastbootjsDispatch({
                    payload: {
                        device_cache: {
                            product_name: newProductName,
                            serial_number: newSerialNumber,
                        },
                    },
                    type: FastbootJSActionType.SET_DEVICE_CACHE,
                });
            } catch (error: any) {
                console.error(`Failed to connect to device: ${error.message}`);
                return;
            }
        }
    }, [fastbootjs_state, fastbootjsDispatch]);

    const disconnectDevice = React.useCallback(async () => {
        if (fastbootjs_state.device !== null) {
            await fastbootjs_state.device.disconnect();
            fastbootjsDispatch({
                type: FastbootJSActionType.RESET,
            });
        }
    }, [fastbootjs_state, fastbootjsDispatch]);

    // -------------------------------------------------------------------------
    // Rendering
    // -------------------------------------------------------------------------
    const deviceInformation = (
        <div>
            <h3>Device Information</h3>
            Product Name:{" "}
            {fastbootjs_state.device_cache.product_name !== null
                ? fastbootjs_state.device_cache.product_name
                : "No Device Connected"}
            <br />
            Serial Number:{" "}
            {fastbootjs_state.device_cache.serial_number !== null
                ? fastbootjs_state.device_cache.serial_number
                : "No Device Connected"}
        </div>
    );

    const final_render_element = (
        <Stack tokens={{ childrenGap: 8, padding: "0 0 8px 8px" }}>
            {deviceInformation}
            <br />
            {is_device_connected === false ? (
                <PrimaryButton
                    iconProps={{ iconName: Icons.PlugConnected }}
                    text="Connect"
                    styles={{ root: { width: "100%" } }}
                    onClick={connectToDevice}
                />
            ) : (
                <DefaultButton
                    iconProps={{ iconName: Icons.PlugDisconnected }}
                    text="Disconnect"
                    onClick={disconnectDevice}
                />
            )}
            <Dialog
                // hidden={!connecting}
                dialogContentProps={{
                    title: "Connecting...",
                    subText: "Please authorize the connection on your device",
                }}
            />
        </Stack>
    );

    return final_render_element;
};

export { FastbootConnectMenuView };
