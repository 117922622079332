import * as common from "./common";
import {
    // CONSTANTS
    CHUNK_HEADER_SIZE,
    ChunkType,
    FILE_HEADER_SIZE,
    FILE_MAGIC,
    ImageError,
    MAJOR_VERSION,
    MINOR_VERSION,
    SparseHeader,
    SparseChunk,
    SparseSplit,
    // Functions
    createImage as oldCreateImage,
    fromRaw as oldFromRaw,
    parseChunkHeader as oldParseChunkHeader,
    parseFileHeader as oldParseFileHeader,
} from "./sparse";

const SPARSE_FILE_HEADER_SIZE_IN_BYTES = 28;

function parseFileHeader(buffer: ArrayBuffer): SparseHeader | null {
    return oldParseFileHeader(buffer);
}

function parseChunkHeader(buffer: ArrayBuffer): SparseChunk {
    return oldParseChunkHeader(buffer);
}

function calculateChunksCumulativeBlocksSize(chunks: Array<SparseChunk>) {
    const chunksCumulativeBlocks = chunks
        .map((chunk) => {
            return chunk.blocks;
        })
        .reduce((total, currentElement) => {
            return total + currentElement;
        }, 0);
    return chunksCumulativeBlocks;
}

function calculateChunksDataCumuluativeSizeInBytes(chunks: Array<SparseChunk>, debug = false): number {
    const debugData: any = {};
    let debugString = "";

    const chunksDataCumulativeSize = chunks
        .map((chunk: SparseChunk, currentIndex: number) => {
            const chunkDataByteLength = chunk.data!.byteLength;
            // ----
            debugData[currentIndex] = [];
            debugData[currentIndex].push(chunkDataByteLength);
            debugString += `- Chunk ${currentIndex}` + ` - ByteLength: ${debugData[currentIndex][0]}\n`;
            // ----
            return chunkDataByteLength;
        })
        .reduce((total: number, currentElement: number) => {
            return total + currentElement;
        }, 0);

    if (debug === true) {
        console.log(`--- calculateChunksDataCumuluativeSizeInBytes ---` + `\n` + debugString);
    }

    return chunksDataCumulativeSize;
}

function calculateChunksCumulativeSizeInBytes(
    fileHeaderSize: number,
    chunkHeaderSize: number,
    chunks: Array<SparseChunk>,
    debug = false
): number {
    // 28-byte file header, 12-byte chunk headers
    const headerSize = fileHeaderSize + chunkHeaderSize * chunks.length;
    const dataSize = calculateChunksDataCumuluativeSizeInBytes(chunks);
    const cumulativeSize = headerSize + dataSize;

    if (debug === true) {
        console.log(
            `--- calculateChunksCumulativeSizeInBytes ---` +
                `\n` +
                `headerSize: ${headerSize}` +
                `\n` +
                `dataSize: ${dataSize}` +
                `\n` +
                `totalSize: ${cumulativeSize}`
        );
    }

    return cumulativeSize;
}

function createImage(header: SparseHeader, chunks: Array<SparseChunk>) {
    return oldCreateImage(header, chunks);
}

function fromRaw(rawBuffer: ArrayBuffer): ArrayBuffer {
    return oldFromRaw(rawBuffer);
}

async function* splitSparseFileBlob(fileBlob: Blob, maxDownloadSizeInBytes: number) {
    console.log(`Splitting ${fileBlob.size}-byte sparse image into ${maxDownloadSizeInBytes}-byte chunks`);

    // --------------------
    // Extract Sparse File Header
    // --------------------
    const fileHeaderBlob = fileBlob.slice(0, FILE_HEADER_SIZE);
    const fileHeaderData = await common.readBlobAsBuffer(fileHeaderBlob);
    const fileHeaderObject: SparseHeader | null = parseFileHeader(fileHeaderData);
    if (fileHeaderObject === null) {
        throw new ImageError("Blob is not a sparse image");
    }
    console.log(`FILE_HEADER_SIZE: ${FILE_HEADER_SIZE}`);
    console.log(fileHeaderData);
    console.log(fileHeaderObject);

    // Remove CRC32 (if present), otherwise splitting will invalidate it
    fileHeaderObject.crc32 = 0;

    // --------------------
    // Iterate Over File Blob And Create Sparse Chunks
    // --------------------
    let currentBlobOffset = 0;
    let processedBlocksInBytes = 0;
    let processedChunks: Array<SparseChunk> = [];

    // Skip the file header
    currentBlobOffset += FILE_HEADER_SIZE;
    for (let i = 0; i < fileHeaderObject.chunks; i++) {
        // ----------------------------------------
        // First Chunk
        // ----------------------------------------
        // Created because the chunks are 1-indexed and not 0-indexed
        const currentChunkIndex = i + 1;
        // If first chunk, then there needs to be a `skip` chunk added at the head
        const isFirstChunkOfSplit = processedChunks.length == 0;
        const isFirstChunkOfFile = currentBlobOffset == FILE_HEADER_SIZE;
        if (isFirstChunkOfSplit == true && isFirstChunkOfFile == false) {
            const alreadySplitBlocks = fileHeaderObject.blocks - calculateChunksCumulativeBlocksSize(processedChunks);
            console.log(`###################`);
            console.log("Starting new split" + "\n" + `Used SKIP chunk to skip ${alreadySplitBlocks} blocks`);
            const firstChunk = {
                type: ChunkType.Skip,
                blocks: alreadySplitBlocks,
                data: new ArrayBuffer(0),
                dataBytes: 0,
            };
            processedChunks.push(firstChunk);
        }

        // console.log(`-------------------`);
        // console.log(
        //     `File Blob Start Offset: 0` +
        //         "\n" +
        //         `File Blob Header Offset: ${FILE_HEADER_SIZE}` +
        //         "\n" +
        //         `Current File Blob Offset: ${currentBlobOffset}`
        // );

        // ----------------------------------------
        // Generate Current Chunk Object
        // ----------------------------------------
        // TODO: I have no idea why I have to create `currentBlob`
        //       ideally we would just be using the `fileBlob` directly to make
        //       slices from but for some reason it blows up if that is done
        const currentBlob = fileBlob.slice(currentBlobOffset);
        const currentSplitSizeInBytes = calculateChunksCumulativeSizeInBytes(
            FILE_HEADER_SIZE,
            CHUNK_HEADER_SIZE,
            processedChunks
        );
        let bytesRemainingInSplit = maxDownloadSizeInBytes - currentSplitSizeInBytes;
        // ----------
        const currentChunkHeaderBlob = currentBlob.slice(0, CHUNK_HEADER_SIZE);
        let currentChunkHeaderData = await common.readBlobAsBuffer(currentChunkHeaderBlob);
        const currentChunkObject: SparseChunk = parseChunkHeader(currentChunkHeaderData);

        const currentChunkDataBlob = currentBlob.slice(
            CHUNK_HEADER_SIZE,
            CHUNK_HEADER_SIZE + currentChunkObject.dataBytes
        );
        currentChunkObject.data = await common.readBlobAsBuffer(currentChunkDataBlob);

        // console.log(
        //     `Global Data` +
        //         "\n" +
        //         `fileBlob.size: ${fileBlob.size}` +
        //         "\n" +
        //         `currentBlobOffset: ${currentBlobOffset}` +
        //         "\n" +
        //         `maxDownloadSizeInBytes: ${maxDownloadSizeInBytes}` +
        //         "\n" +
        //         `Total Chunks: ${fileHeaderObject.chunks}`
        // );
        // console.log(
        //     `Current Chunk: ${currentChunkIndex}/${fileHeaderObject.chunks}` +
        //         "\n" +
        //         `Header Size: ${CHUNK_HEADER_SIZE}` +
        //         "\n" +
        //         `Type (${ChunkType[currentChunkObject.type]}): ${currentChunkObject.type}` +
        //         "\n" +
        //         `${currentChunkObject.dataBytes} bytes / ${currentChunkObject.blocks} blocks`
        // );
        // console.log(currentChunkObject);

        // ----------------------------------------
        // Add Chunk Object to Split
        // ----------------------------------------
        const isSplitFull = bytesRemainingInSplit <= currentChunkObject.dataBytes;
        if (isSplitFull == false) {
            // console.log("Adding chunk to split");
            processedChunks.push(currentChunkObject);
            processedBlocksInBytes += currentChunkObject.blocks * fileHeaderObject.blockSize;
            // Update Blob Position
            currentBlobOffset += CHUNK_HEADER_SIZE + currentChunkObject.dataBytes;
        }

        // ----------------------------------------
        // Finish Split
        // ----------------------------------------
        const haveAllChunksBeenProcessed = currentChunkIndex == fileHeaderObject.chunks;
        if (isSplitFull == true || haveAllChunksBeenProcessed == true) {
            console.log("Split is full");
            // Blocks need to be calculated from chunk headers instead of going by size
            // because FILL and SKIP chunks cover more blocks than the data they contain.
            const splitBlocks = calculateChunksCumulativeBlocksSize(processedChunks);
            processedChunks.push({
                type: ChunkType.Skip,
                blocks: fileHeaderObject.blocks - splitBlocks,
                data: new ArrayBuffer(0),
                dataBytes: 0,
            });
            // ----------------------------------------
            let splitImage = createImage(fileHeaderObject, processedChunks);
            yield {
                data: splitImage,
                bytes: splitBlocks,
            } as SparseSplit;

            // ----------------------------------------
            // Reset For Next Split
            // ----------------------------------------
            let processedBlocksInBytes = 0;
            processedChunks = [];
            // WARNING: The current iteration MUST be repeated because it
            //          wasn't added to the split, so re-run the logic for this
            //          chunk and it should get added in the next split because
            //          of the reset
            // WARNING: Do NOT repeat iteration on final chunk otherwise it ends
            //          up looping infinitely
            if (haveAllChunksBeenProcessed == false) {
                // console.log("Resetting for next split");
                i--;
            } else {
                // console.log("All chunks have been processed.");
            }
        }
    }
}

async function extractSignatureArrayBuffer(fileBlob: Blob) {
    console.log(`Extracting signature`);

    // --------------------
    // Extract Sparse File Header
    // --------------------
    const fileHeaderBlob = fileBlob.slice(0, FILE_HEADER_SIZE);
    const fileHeaderData = await common.readBlobAsBuffer(fileHeaderBlob);
    const fileHeaderObject: SparseHeader | null = parseFileHeader(fileHeaderData);
    if (fileHeaderObject === null) {
        throw new ImageError("Blob is not a sparse image");
    }
    console.log(`File Header` + "\n" + `File Header Size: ${FILE_HEADER_SIZE}`);
    console.log(fileHeaderData);
    console.log(fileHeaderObject);

    // Remove CRC32 (if present), otherwise splitting will invalidate it
    fileHeaderObject.crc32 = 0;

    // --------------------
    // Iterate Over File Blob And Create Sparse Chunks
    // --------------------
    let currentBlobOffset = 0;
    let processedBlocksInBytes = 0;
    let processedChunks: Array<SparseChunk> = [];

    // Skip the file header
    currentBlobOffset += FILE_HEADER_SIZE;
    for (let i = 0; i < fileHeaderObject.chunks; i++) {
        // ----------------------------------------
        // First Chunk
        // ----------------------------------------
        // Created because the chunks are 1-indexed and not 0-indexed
        const currentChunkIndex = i + 1;

        // console.log(`-------------------`);
        // console.log(
        //     `Chunk ${currentChunkIndex}/${fileHeaderObject.chunks}` +
        //         "\n" +
        //         `Current File Blob Offset: ${currentBlobOffset}`
        // );

        // ----------------------------------------
        // Generate Current Chunk Object
        // ----------------------------------------
        // TODO: I have no idea why I have to create `currentBlob`
        //       ideally we would just be using the `fileBlob` directly to make
        //       slices from but for some reason it blows up if that is done
        const currentBlob = fileBlob.slice(currentBlobOffset);
        const currentSplitSizeInBytes = calculateChunksCumulativeSizeInBytes(
            FILE_HEADER_SIZE,
            CHUNK_HEADER_SIZE,
            processedChunks
        );
        // ----------
        const currentChunkHeaderBlob = currentBlob.slice(0, CHUNK_HEADER_SIZE);
        let currentChunkHeaderData = await common.readBlobAsBuffer(currentChunkHeaderBlob);
        const currentChunkObject: SparseChunk = parseChunkHeader(currentChunkHeaderData);

        currentBlobOffset += CHUNK_HEADER_SIZE + currentChunkObject.dataBytes;
    }
    // ----------------------------------------
    // Get Signature Blob
    // ----------------------------------------
    console.log(
        "Iterated through all chunks" +
            "\n" +
            `fileBlob.size: ${fileBlob.size}` +
            "\n" +
            `currentBlobOffset: ${currentBlobOffset}` +
            "\n" +
            `remainingData: ${fileBlob.size - currentBlobOffset}` +
            "\n" +
            `remainingData (hex): ${(fileBlob.size - currentBlobOffset).toString(16)};
                    `
    );
    const signatureBlob = fileBlob.slice(currentBlobOffset);
    console.log(`signatureBlob.size: ${signatureBlob.size}`);

    // ----------------------------------------
    // Create ArrayBuffer For Upload
    // ----------------------------------------
    const signatureArrayBuffer = common.readBlobAsBuffer(signatureBlob);

    return signatureArrayBuffer;
}

export {
    // Constants
    SPARSE_FILE_HEADER_SIZE_IN_BYTES,
    // Functions
    extractSignatureArrayBuffer,
    fromRaw,
    parseFileHeader,
    splitSparseFileBlob,
};
