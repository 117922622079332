// Third-Party
import {
    IComponentAsProps,
    IconButton,
    INavButtonProps,
    mergeStyles,
    mergeStyleSets,
    Nav,
    Stack,
    StackItem,
} from "@fluentui/react";
import type { AppProps } from "next/app";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
// Custom
import { FastbootJSProvider } from "../components/FastbootJS";
// import { Connect, ErrorDialogProvider } from "../components";
import { FastbootConnectMenu } from "../components/FastbootConnectMenu";
import { PageStyle } from "../components/PageStyle";
import { Icons } from "../utils";
import { register as registerIcons } from "../utils/icons";
// Resources
import "../styles/globals.css";

registerIcons();

function App({ Component, pageProps }: AppProps) {
    const final_render_element = (
        <FastbootJSProvider>
            <PageStyle Component={Component} pageProps={pageProps} />
        </FastbootJSProvider>
    );

    return final_render_element;
}

export default App;
