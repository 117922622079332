// Third-Party
import {
    IComponentAsProps,
    IconButton,
    INavButtonProps,
    mergeStyles,
    mergeStyleSets,
    Nav,
    Stack,
    StackItem,
} from "@fluentui/react";
import type { AppProps } from "next/app";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
// Custom
import { FastbootJSProvider } from "../FastbootJS";
// import { Connect, ErrorDialogProvider } from "../components";
import { FastbootConnectMenu } from "../FastbootConnectMenu";
import { Icons } from "../../utils";
import { register as registerIcons } from "../../utils/icons";
// Resources
// N/A

registerIcons();

const ROUTES = [
    // {
    //     url: "/step-by-step",
    //     icon: Icons.Folder,
    //     name: "Step-By-Step Flashing",
    // },
    {
        url: "/one-click",
        icon: Icons.Box,
        name: "One-Click Flashing",
    },
];

function NavLink({ link, defaultRender: DefaultRender, ...props }: IComponentAsProps<INavButtonProps>) {
    if (!link) {
        return null;
    }

    const final_render_element = (
        <Link href={link.url} passHref>
            <DefaultRender {...props} />
        </Link>
    );

    return final_render_element;
}

function PageStyle({ Component, pageProps }: any) {
    const classNames = mergeStyleSets({
        "title-container": {
            borderBottom: "1px solid rgb(243, 242, 241)",
        },
        title: {
            padding: "4px 0",
            fontSize: 20,
            textAlign: "center",
        },
        "left-column": {
            width: 270,
            paddingRight: 8,
            borderRight: "1px solid rgb(243, 242, 241)",
            overflow: "auto",
        },
        "right-column": {
            borderLeft: "1px solid rgb(243, 242, 241)",
        },
    });

    const [leftPanelVisible, setLeftPanelVisible] = useState(false);
    const toggleLeftPanel = useCallback(() => {
        setLeftPanelVisible((value) => !value);
    }, []);
    useEffect(() => {
        setLeftPanelVisible(innerWidth > 650);
    }, []);

    const router = useRouter();

    const final_render_element = (
        <>
            <Head>
                <link rel="manifest" href="/manifest.json" />
            </Head>

            <Stack verticalFill>
                <Stack className={classNames["title-container"]} horizontal verticalAlign="center">
                    <IconButton
                        checked={leftPanelVisible}
                        title="Toggle Menu"
                        iconProps={{ iconName: Icons.Navigation }}
                        onClick={toggleLeftPanel}
                    />

                    <StackItem grow>
                        <div
                            className={classNames.title}
                            onClick={() => {
                                router.push("/");
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            Magic Leap 1 Device Flasher
                        </div>
                    </StackItem>
                </Stack>

                <Stack
                    grow
                    horizontal
                    verticalFill
                    disableShrink
                    styles={{ root: { minHeight: 0, overflow: "hidden", lineHeight: "1.5" } }}
                >
                    <StackItem
                        className={mergeStyles(classNames["left-column"], !leftPanelVisible && { display: "none" })}
                    >
                        <br />
                        <FastbootConnectMenu />
                        <hr />

                        <Nav
                            groups={[
                                {
                                    links: ROUTES.map((route) => ({
                                        ...route,
                                        key: route.url,
                                    })),
                                },
                            ]}
                            linkAs={NavLink}
                            selectedKey={router.pathname}
                        />
                    </StackItem>

                    <StackItem grow style={{ overflow: "auto" }} styles={{ root: { width: 0 } }}>
                        <Component {...pageProps} />
                    </StackItem>
                </Stack>
            </Stack>
        </>
    );

    return final_render_element;
}

export { PageStyle };
