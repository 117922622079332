import * as common from "./common";

export const FILE_MAGIC = 0xed26ff3a;

export const MAJOR_VERSION = 1;
export const MINOR_VERSION = 0;
export const FILE_HEADER_SIZE = 28;
export const CHUNK_HEADER_SIZE = 12;

// AOSP libsparse uses 64 MiB chunks
export const RAW_CHUNK_SIZE = 64 * 1024 * 1024;

export class ImageError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ImageError";
    }
}

export interface SparseSplit {
    data: ArrayBuffer;
    bytes: number;
}

export enum ChunkType {
    Raw = 0xcac1,
    Fill = 0xcac2,
    Skip = 0xcac3,
    Crc32 = 0xcac4,
}

export interface SparseHeader {
    blockSize: number;
    blocks: number;
    chunks: number;
    crc32: number;
    // NEW
    chunkHdrSize: number;
    fileHdrSize: number;
    magic: number;
    magic_hex: string;
    major: number;
    minor: number;
}

export interface SparseChunk {
    type: ChunkType;
    /* 2: reserved, 16 bits */
    blocks: number;
    dataBytes: number;
    data: ArrayBuffer | null; // to be populated by consumer
}

/**
 * Returns a parsed version of the sparse image file header from the given buffer.
 *
 * @param {ArrayBuffer} buffer - Raw file header data.
 * @returns {SparseHeader} Object containing the header information.
 */
export function parseFileHeader(buffer: ArrayBuffer): SparseHeader | null {
    let view = new DataView(buffer);

    let magic = view.getUint32(0, true);
    if (magic !== FILE_MAGIC) {
        return null;
    }
    console.log(`magic: ${magic}`);
    console.log(`magic (hex): ${magic.toString(16)}`);

    // v1.0+
    let major = view.getUint16(4, true);
    let minor = view.getUint16(6, true);
    if (major !== MAJOR_VERSION || minor < MINOR_VERSION) {
        throw new ImageError(`Unsupported sparse image version ${major}.${minor}`);
    }
    console.log(`major: ${major}`);
    console.log(`minor: ${minor}`);

    let fileHdrSize = view.getUint16(8, true);
    let chunkHdrSize = view.getUint16(10, true);
    if (fileHdrSize !== FILE_HEADER_SIZE || chunkHdrSize !== CHUNK_HEADER_SIZE) {
        throw new ImageError(`Invalid file header size ${fileHdrSize}, chunk header size ${chunkHdrSize}`);
    }
    console.log(`fileHdrSize: ${fileHdrSize}`);
    console.log(`chunkHdrSize: ${chunkHdrSize}`);

    let blockSize = view.getUint32(12, true);
    if (blockSize % 4 !== 0) {
        throw new ImageError(`Block size ${blockSize} is not a multiple of 4`);
    }
    console.log(`blockSize: ${blockSize}`);

    const headerData = {
        blockSize: blockSize,
        blocks: view.getUint32(16, true),
        chunks: view.getUint32(20, true),
        crc32: view.getUint32(24, true),
        // NEW
        chunkHdrSize: chunkHdrSize,
        fileHdrSize: fileHdrSize,
        magic: magic,
        magic_hex: `0x${magic.toString(16)}`,
        major: major,
        minor: minor,
    };

    return headerData;
}

export function parseChunkHeader(buffer: ArrayBuffer): SparseChunk {
    let view = new DataView(buffer);

    // This isn't the same as what createImage takes.
    // Further processing needs to be done on the chunks.
    return {
        type: view.getUint16(0, true),
        /* 2: reserved, 16 bits */
        blocks: view.getUint32(4, true),
        dataBytes: view.getUint32(8, true) - CHUNK_HEADER_SIZE,
        data: null, // to be populated by consumer
    };
}

function calcChunksBlockSize(chunks: Array<SparseChunk>) {
    return chunks.map((chunk) => chunk.blocks).reduce((total, c) => total + c, 0);
}

function calcChunksDataSize(chunks: Array<SparseChunk>, debug = false) {
    const debugData: any = {};
    let debugString = "";

    const chunkDataSize = chunks
        .map((chunk, currentIndex) => {
            const chunkDataByteLength = chunk.data!.byteLength;
            // ----
            debugData[currentIndex] = [];
            debugData[currentIndex].push(chunkDataByteLength);
            debugString += `- Chunk ${currentIndex}` + ` - ByteLength: ${debugData[currentIndex][0]}` + "\n";
            // ----

            return chunkDataByteLength;
        })
        .reduce((total, currentElement, currentIndex) => {
            const newTotal = total + currentElement;

            return newTotal;
        }, 0);

    if (debug == true) {
        console.log(`--- calcChunksDataSize ---` + `\n` + debugString);
    }

    return chunkDataSize;
}

function calcChunksSize(chunks: Array<SparseChunk>, debug = false): number {
    // 28-byte file header, 12-byte chunk headers
    const headerSize = FILE_HEADER_SIZE + CHUNK_HEADER_SIZE * chunks.length;
    const dataSize = calcChunksDataSize(chunks, debug);
    const totalSize = headerSize + dataSize;
    if (debug == true) {
        console.log(
            `--- calcChunksSize ---` +
                `\n` +
                `headerSize: ${headerSize}` +
                `\n` +
                `dataSize: ${dataSize}` +
                `\n` +
                `totalSize: ${totalSize}`
        );
    }

    return totalSize;
}

export function createImage(header: SparseHeader, chunks: Array<SparseChunk>) {
    let buffer = new ArrayBuffer(calcChunksSize(chunks, true));
    let dataView = new DataView(buffer);
    let arrayView = new Uint8Array(buffer);

    dataView.setUint32(0, FILE_MAGIC, true);
    // v1.0
    dataView.setUint16(4, MAJOR_VERSION, true);
    dataView.setUint16(6, MINOR_VERSION, true);
    dataView.setUint16(8, FILE_HEADER_SIZE, true);
    dataView.setUint16(10, CHUNK_HEADER_SIZE, true);

    // Match input parameters
    dataView.setUint32(12, header.blockSize, true);
    dataView.setUint32(16, header.blocks, true);
    dataView.setUint32(20, chunks.length, true);

    // We don't care about the CRC. AOSP docs specify that this should be a CRC32,
    // but AOSP libsparse always sets 0 and puts the CRC in a final undocumented
    // 0xCAC4 chunk instead.
    dataView.setUint32(24, 0, true);

    let chunkOff = FILE_HEADER_SIZE;
    for (let chunk of chunks) {
        dataView.setUint16(chunkOff, chunk.type, true);
        dataView.setUint16(chunkOff + 2, 0, true); // reserved
        dataView.setUint32(chunkOff + 4, chunk.blocks, true);
        dataView.setUint32(chunkOff + 8, CHUNK_HEADER_SIZE + chunk.data!.byteLength, true);
        chunkOff += CHUNK_HEADER_SIZE;

        let chunkArrayView = new Uint8Array(chunk.data!);
        arrayView.set(chunkArrayView, chunkOff);
        chunkOff += chunk.data!.byteLength;
    }

    return buffer;
}

/**
 * Creates a sparse image from buffer containing raw image data.
 *
 * @param {ArrayBuffer} rawBuffer - Buffer containing the raw image data.
 * @returns {ArrayBuffer} Buffer containing the new sparse image.
 */
export function fromRaw(rawBuffer: ArrayBuffer): ArrayBuffer {
    const header: SparseHeader = {
        blockSize: 4096,
        blocks: rawBuffer.byteLength / 4096,
        chunks: 1,
        crc32: 0,
        // NEW
        chunkHdrSize: CHUNK_HEADER_SIZE,
        fileHdrSize: FILE_HEADER_SIZE,
        magic: FILE_MAGIC,
        magic_hex: `0x${FILE_MAGIC.toString(16)}`,
        major: MAJOR_VERSION,
        minor: MINOR_VERSION,
    };

    let chunks = [];
    while (rawBuffer.byteLength > 0) {
        let chunkSize = Math.min(rawBuffer.byteLength, RAW_CHUNK_SIZE);
        chunks.push({
            type: ChunkType.Raw,
            blocks: chunkSize / header.blockSize,
            data: rawBuffer.slice(0, chunkSize),
        } as SparseChunk);
        rawBuffer = rawBuffer.slice(chunkSize);
    }

    return createImage(header, chunks);
}

/**
 * Split a sparse image into smaller sparse images within the given size.
 * This takes a Blob instead of an ArrayBuffer because it may process images
 * larger than RAM.
 *
 * @param {Blob} blob - Blob containing the sparse image to split.
 * @param {number} splitSize - Maximum size per split.
 * @yields {Object} Data of the next split image and its output size in bytes.
 */
export async function* splitBlob(blob: Blob, maxDownloadSize: number, forceChunking = false) {
    common.logDebug(`Splitting ${blob.size}-byte sparse image into ${maxDownloadSize}-byte chunks`);
    // Short-circuit if splitting isn't required
    if (blob.size <= maxDownloadSize && forceChunking === false) {
        common.logDebug("Blob fits in 1 payload, not splitting");
        yield {
            data: await common.readBlobAsBuffer(blob),
            bytes: blob.size,
        } as SparseSplit;
        return;
    }

    let headerData = await common.readBlobAsBuffer(blob.slice(0, FILE_HEADER_SIZE));
    let header = parseFileHeader(headerData);
    if (header === null) {
        throw new ImageError("Blob is not a sparse image");
    }
    console.log(headerData);
    console.log(header);
    console.log(`FILE_HEADER_SIZE: ${FILE_HEADER_SIZE}`);

    // Remove CRC32 (if present), otherwise splitting will invalidate it
    header.crc32 = 0;
    blob = blob.slice(FILE_HEADER_SIZE);

    let splitChunks: Array<SparseChunk> = [];
    let splitDataBytes = 0;
    /**
     * overhead is sparse file header, the potential end skip
     * chunk and crc chunk.
     *
     * See system/core/libsparse/sparse.c @ move_chunks_up_to_len
     */
    const overhead: number =
        FILE_HEADER_SIZE /* Image Header */ +
        CHUNK_HEADER_SIZE /* The very last chunk may be SKIP */ +
        CHUNK_HEADER_SIZE /* The CRC32 chunk header */ +
        4; /*     CRC32 */
    maxDownloadSize -= overhead;

    console.log("----------------------");

    /**
     * Idea here is to produce set of images that can be flashed
     * independently.
     *
     * Every image is "flashing program"
     *
     * 1. skip X already flashed blocks
     * 2. flash Y new blocks
     * 3. skip Z remaining blocks
     *
     * X is continiously increasing, Z is continuosly decreasing
     * X[n] = X[n-1] + Y[n-1]
     * Z[n] = sparse_header.total_blks - X[n]
     */
    for (let i = 0; i < header.chunks; i++) {
        let chunkHeaderData = await common.readBlobAsBuffer(blob.slice(0, CHUNK_HEADER_SIZE));
        let chunk = parseChunkHeader(chunkHeaderData);
        chunk.data = await common.readBlobAsBuffer(blob.slice(CHUNK_HEADER_SIZE, CHUNK_HEADER_SIZE + chunk.dataBytes));
        blob = blob.slice(CHUNK_HEADER_SIZE + chunk.dataBytes);

        const bytesRemaining = maxDownloadSize - calcChunksSize(splitChunks);
        // common.logVerbose(
        //     `  Chunk ${i}: type ${chunk.type}, ${chunk.dataBytes} bytes / ${chunk.blocks} blocks, ${bytesRemaining} bytes remaining`
        // );
        console.log("-----");
        // console.log(
        //     `Global Data` + "\n" + `maxDownloadSize (max-download-size): ${maxDownloadSize}` + "\n" + `blob.size: ${blob.size}`
        // );
        console.log(
            `Current Chunk: ${i}/${header.chunks}` +
                "\n" +
                `Type (${ChunkType[chunk.type]}): ${chunk.type}` +
                "\n" +
                `${chunk.dataBytes} bytes / ${chunk.blocks} blocks` +
                "\n" +
                `Bytes Remaining: ${bytesRemaining}`
        );

        if (bytesRemaining >= chunk.dataBytes) {
            // Read the chunk and add it
            common.logVerbose("    Space is available, adding chunk");
            collapseSkipChunksIfNeeded(chunk, splitChunks);

            // Track amount of data written on the output device, in bytes
            splitDataBytes += chunk.blocks * header.blockSize;
        } else if (bytesRemaining > maxDownloadSize >> 3) {
            common.logDebug(`Out of Space - But Not Really`);

            const realBlocksRemaining = Math.floor(bytesRemaining / 4096);
            const realBytesRemaining = realBlocksRemaining * 4096; // 130000704

            splitChunks.push({
                type: ChunkType.Raw,
                blocks: realBlocksRemaining,
                dataBytes: realBytesRemaining,
                data: chunk.data?.slice(0, realBytesRemaining) as ArrayBuffer,
            });

            //--------------------------------------------------------------------------------
            // Blocks need to be calculated from chunk headers instead of going by size
            // because FILL and SKIP chunks cover more blocks than the data they contain.
            let splitBlocks: number = calcChunksBlockSize(splitChunks);
            const finalSkipChunk = {
                type: ChunkType.Skip,
                blocks: header.blocks - splitBlocks,
                data: new ArrayBuffer(0),
                dataBytes: 0,
            };

            splitChunks.push(finalSkipChunk);

            let splitImage = createImage(header, splitChunks);
            common.logDebug(`Finished ${splitImage.byteLength}-byte split with ${splitChunks.length} chunks`);
            yield {
                data: splitImage,
                bytes: splitDataBytes,
            } as SparseSplit;

            // -----------------------------------------------------------------
            // Start of next split
            let leftoverBytes: number = chunk.dataBytes - realBytesRemaining;
            let leftoverBlocks: number = chunk.blocks - realBlocksRemaining;

            let chunkDataSecondDataBlobArrayBuffer: ArrayBuffer | null = chunk.data?.slice(
                realBytesRemaining
            ) as ArrayBuffer;
            if (chunkDataSecondDataBlobArrayBuffer.byteLength != leftoverBytes) {
                throw new ImageError(
                    `LeftoverBytes ${leftoverBytes}, chunk length ${chunkDataSecondDataBlobArrayBuffer.byteLength}`
                );
            }

            const canFitBlocks: number = Math.floor(maxDownloadSize / header.blockSize);
            const canFitBytes: number = canFitBlocks * header.blockSize;

            while (leftoverBytes > maxDownloadSize) {
                splitChunks = [
                    {
                        type: ChunkType.Skip,
                        blocks: splitBlocks,
                        data: new ArrayBuffer(0),
                        dataBytes: 0,
                    },

                    {
                        type: ChunkType.Raw,
                        blocks: canFitBlocks,
                        data: chunkDataSecondDataBlobArrayBuffer.slice(0, canFitBytes),
                        dataBytes: canFitBytes,
                    },

                    /* Final Skip */
                    {
                        type: ChunkType.Skip,
                        blocks: header.blocks - splitBlocks - canFitBlocks,
                        data: new ArrayBuffer(0),
                        dataBytes: 0,
                    },
                ];

                splitBlocks += /* DATA */ canFitBlocks;
                leftoverBytes -= canFitBytes;
                leftoverBlocks -= canFitBlocks;
                chunkDataSecondDataBlobArrayBuffer = chunkDataSecondDataBlobArrayBuffer?.slice(canFitBytes);

                yield {
                    data: createImage(header, splitChunks),
                    bytes: 0 /* TODO */,
                } as SparseSplit;
            }

            const secondChunkPortion: SparseChunk = {
                type: ChunkType.Raw,
                blocks: leftoverBlocks,
                dataBytes: leftoverBytes,
                data: chunkDataSecondDataBlobArrayBuffer,
            };

            // Start a new split. Every split is considered a full image by the
            // bootloader, so we need to skip the *total* written blocks.
            common.logVerbose(`Starting new split: skipping first ${splitBlocks} blocks and adding chunk`);
            splitChunks = [
                {
                    type: ChunkType.Skip,
                    blocks: splitBlocks,
                    data: new ArrayBuffer(0),
                    dataBytes: 0,
                },
                secondChunkPortion,
            ];
            splitDataBytes = 0;
        } else {
            common.logDebug(`Out of Space`);
            // Out of space, finish this split
            //--------------------------------------------------------------------------------
            // Blocks need to be calculated from chunk headers instead of going by size
            // because FILL and SKIP chunks cover more blocks than the data they contain.
            let splitBlocks = calcChunksBlockSize(splitChunks);
            const finalSkipChunk = {
                type: ChunkType.Skip,
                blocks: header.blocks - splitBlocks,
                data: new ArrayBuffer(0),
                dataBytes: 0,
            };

            collapseSkipChunksIfNeeded(finalSkipChunk, splitChunks);

            console.log("-- Final Skip Chunk --");
            console.log(`header.blocks: ${header.blocks}`);
            console.log(`splitBlocks: ${splitBlocks}`);
            console.log(finalSkipChunk);
            // common.logVerbose(
            //     `Partition is ${header.blocks} blocks, used ${splitBlocks}, padded with ${
            //         header.blocks - splitBlocks
            //     }, finishing split with ${calcChunksBlockSize(splitChunks)} blocks`
            // );
            console.log(
                `File Total Block Count: ${header.blocks}` +
                    "\n" +
                    `splitBlocks: ${splitBlocks}` +
                    "\n" +
                    `Padded blocks: ${header.blocks - splitBlocks}` +
                    "\n" +
                    `Total blocks processed: ${splitBlocks + (header.blocks - splitBlocks)}`
            );
            console.log("Split is full");
            let splitImage = createImage(header, splitChunks);
            common.logDebug(`Finished ${splitImage.byteLength}-byte split with ${splitChunks.length} chunks`);
            yield {
                data: splitImage,
                bytes: splitDataBytes,
            } as SparseSplit;
            console.log("----------------------");
            // Start a new split. Every split is considered a full image by the
            // bootloader, so we need to skip the *total* written blocks.
            common.logVerbose(`Starting new split: skipping first ${splitBlocks} blocks and adding chunk`);
            splitChunks = [
                {
                    type: ChunkType.Skip,
                    blocks: splitBlocks,
                    data: new ArrayBuffer(0),
                    dataBytes: 0,
                },
                chunk,
            ];
            splitDataBytes = 0;
        }
    }

    // Finish the final split if necessary
    if (splitChunks.length > 0 && (splitChunks.length > 1 || splitChunks[0].type !== ChunkType.Skip)) {
        let splitImage = createImage(header, splitChunks);
        common.logDebug(`Finishing final ${splitImage.byteLength}-byte split with ${splitChunks.length} chunks`);
        yield {
            data: splitImage,
            bytes: splitDataBytes,
        } as SparseSplit;
    }
}

function formatChunkSz(chunk: SparseChunk) {
    return String(chunk.blocks).padStart(5, " ");
}

function collapseSkipChunksIfNeeded(chunk: SparseChunk, splitChunks: SparseChunk[]) {
    if (
        chunk.type == ChunkType.Skip &&
        splitChunks.length > 0 &&
        splitChunks[splitChunks.length - 1].type == ChunkType.Skip
    ) {
        console.log(
            `[DBG]add ${ChunkType[chunk.type]} ${formatChunkSz(chunk)} block(s) ${chunk.dataBytes + CHUNK_HEADER_SIZE}`
        );

        splitChunks[splitChunks.length - 1].blocks += chunk.blocks;
        splitChunks[splitChunks.length - 1].dataBytes += chunk.dataBytes;
    } else {
        splitChunks.push(chunk);
        console.log(
            `[DBG]xxx ${ChunkType[chunk.type]} ${formatChunkSz(chunk)} block(s) ${chunk.dataBytes + CHUNK_HEADER_SIZE}`
        );
    }
}
