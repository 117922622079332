const DB_NAME = "BlobStore";
const DB_VERSION = 1;

export class BlobStore {
    db: any = null;

    constructor() {
        this.db = null;
    }

    async _wrapReq(request: any, onUpgrade: any = null) {
        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                resolve(request.result);
            };
            request.oncomplete = () => {
                resolve(request.result);
            };
            request.onerror = (event: any) => {
                reject(event);
            };

            if (onUpgrade !== null) {
                request.onupgradeneeded = onUpgrade;
            }
        });
    }

    async init() {
        if (this.db === null) {
            this.db = await this._wrapReq(indexedDB.open(DB_NAME, DB_VERSION), (event: any) => {
                let db = event.target.result;
                db.createObjectStore("files", { keyPath: "name" });
                /* no index needed for such a small database */
            });
        }
    }

    async saveFile(name: any, blob: any) {
        this.db.transaction(["files"], "readwrite").objectStore("files").add({
            name: name,
            blob: blob,
        });
    }

    async loadFile(name: any) {
        try {
            let obj: any = await this._wrapReq(this.db.transaction("files").objectStore("files").get(name));
            return obj.blob;
        } catch (error) {
            return null;
        }
    }

    async close() {
        this.db.close();
    }

    /**
     * Downloads the file from the given URL and saves it to this BlobStore.
     *
     * @param {string} url - URL of the file to download.
     * @returns {Promise<Blob>} Blob containing the downloaded data.
     */
    async download(url: string) {
        let filename = url.split("/").pop();
        let blob = await this.loadFile(filename);
        if (blob === null) {
            console.log(`Downloading ${url}`);
            let resp = await fetch(new Request(url));
            blob = await resp.blob();
            console.log("File downloaded, saving...");
            await this.saveFile(filename, blob);
            console.log("File saved");
        } else {
            console.log(`Loaded ${filename} from blob store, skipping download`);
        }

        return blob;
    }
}
